import React from 'react'
import PageHead from 'src/components/head/PageHead'
import {
  SubscribeSuspended,
  UnsubscribeForm,
  UpdateSubscribeForm,
  NewslatterWrapper,
} from 'src/components/newsletter'

import { NEWSLETTER_GROUPS_ENABLED, SUBSCRIBE_PREFERENCES_SUSPENDED } from 'src/constants'

// helpers.
const page = 'investor-newsletter-unsubscribe'

// Main component:
const UpdatePreferencesModal = ({ location }) => (
  <NewslatterWrapper>
    {({ animate }) =>
      SUBSCRIBE_PREFERENCES_SUSPENDED ? (
        <SubscribeSuspended animate={animate} />
      ) : NEWSLETTER_GROUPS_ENABLED ? (
        <UpdateSubscribeForm animate={animate} location={location} />
      ) : (
        <UnsubscribeForm
          animate={animate}
          location={location}
          page={page}
          endpoint="users/investors-cancel-subscription"
        />
      )
    }
  </NewslatterWrapper>
)

export default UpdatePreferencesModal

// <head> component:
export function Head() {
  return <PageHead page={page} noindex />
}
